// .openSeaContainer{
//   position: relative;
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   background-color: black;

//   padding: 10px 0;
  
//   margin: 0 0 20px 0;

//   background-repeat: no-repeat;
//   background-size: auto;
//   background-position: center;


//   .message{
//     display: flex;
//     position: absolute;
//     left: 50%;
//     transform: translateX(-50%);
//     gap: 10px;
//     align-items: center;
//     p{
//       font-size: 14px;
//       font-family: 'Open Sans', sans-serif;
//       font-weight: 500;
//       color: white;
//     }

//     img{
//       width: 20px;
//       height: 20px;
//     }
//   }

//   .close{
//     margin-left: auto;
//     margin-right: 5%;
//     img{
//       width: 20px;
//       height: 20px;
//     }
//   }
// }

.header {
  margin: 0 auto;
  padding: 15px 20px;
  text-align: left;
  position: fixed;
  z-index: 10;
  background-color: white;
  width: 100vw;

  .mainContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .menuIcon{
    width: 20px;
    top: 0;
    display: none;
  }
  .logo {
    text-align: left;
    margin-right: auto;
    color: black;
    display: flex;
    align-items: center;
    img {
      width: 250px;
    }
  }

  .tabs, .mobileTabs{
    display: flex;
    flex-grow: 4;
    padding-left: 50px;
    gap: 50px;
    align-items: flex-start;
    div{
      font-weight: 500;
      font-size: 16px;
      text-transform: capitalize;
      &.active{
        border-bottom: 1px solid black;
        font-weight: 700;
      }
    }
  }

  .mobileTabs{
    display: none;
  }


  .wallet {
    //margin-top: 10px;
    border: 1px solid black;
    background-color: white;
    padding: 5px 15px;
    border-radius: 20px;
    font-weight: 600;
    color: black;
    cursor: pointer;
    user-select: none;
  }

  .wscBalance{
    color: black;
    text-align: right;
    margin-top: 5px;
    margin-right: 10px;
  }

  @media (max-width: 925px) {
    padding: 15px 20px;

    .logo {
      img {
        width: 150px;
      }
    }

    .menuIcon{
      display: block;
    }

    .tabs{
      display: none;
    }

    .mobileTabsWrapper{
      width: 100vw;
      z-index: 2;
      display: flex;

      position: fixed;
      left: 0;
      top: 0;
      height: 100vh;
      transition: transform 0.3s ease;
      transform: translateX(-100%);
      .mobileTabs{

        border: 1px solid lightgrey;
        display: flex;
        flex-direction: column;
        width: 70%;

        background: white;
        padding: 50px 20px;
        text-align: center;
        gap: 30px;
        img{
          width: 80%;
          max-width: 200px;
          display: block;
          margin: 0 auto;
        }
        div, a{
          text-align: center;
          margin: 0 auto;
        }
      }

      .shadow{
        background: grey;
        width: 30%;
        opacity: 0.7;
      }

      .wallet {
        font-size: 12px;
        padding: 3px 15px;
        letter-spacing: -1px;
    }
    }
  }


  @media (max-width: 765px) {
    padding: 15px 20px;

    .logo {
      img {
        width: 150px;
      }
    }

    .menuIcon{
      display: block;
    }

    .tabs{
      display: none;
    }

    .mobileTabsWrapper{
      width: 100vw;
      z-index: 2;
      display: flex;

      position: fixed;
      left: 0;
      top: 0;
      height: 100vh;
      transition: transform 0.3s ease;
      transform: translateX(-100%);
      .mobileTabs{

        border: 1px solid lightgrey;
        display: flex;
        flex-direction: column;
        width: 70%;

        background: white;
        padding: 100px 20px;
        text-align: center;
        gap: 30px;
        img{
          width: 80%;
          max-width: 200px;
          display: block;
          margin: 0 auto 50px auto;
        }
        div, a{
          text-align: center;
          margin: 0 auto;
        }
      }

      .shadow{
        background: grey;
        width: 30%;
        opacity: 0.7;
      }

    }



     .wallet {
        font-size: 12px;
        padding: 3px 15px;
        letter-spacing: -1px;
    }
  }
}
