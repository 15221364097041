.faq{
  padding-bottom: 50px;
  scroll-behavior: smooth;
  .contentWrapper{
    border-top: 1px solid lightgrey;

    .ant-collapse{
      border: none;
      background-color: transparent;
      .ant-collapse-item{
        //border-bottom: none;

        .ant-collapse-header{
          padding: 10px 5px;
        }
      }
    }

    h3{
      display: flex;
      justify-content: space-between;
      align-items: center;
      user-select: none;
      font-weight: 600;
      font-size: 20px;
      letter-spacing: 0.5px;
      img{
        width: 40px;
      }
    }

    p{
      color: grey;
      font-weight: 500;
      font-style: italic;
      font-size: 17px !important;
    }

    a{
      p{
        color: #4587ea !important;
      }

    }
  }

  @media(max-width: 765px){
    .contentWrapper{

      h3{
        font-size: 16px;
        letter-spacing: 0.5px;
        img{
          width: 30px;
        }
      }

      p{
        font-size: 14px !important;
      }
      .ant-collapse-content-box{
        padding: 10px 5px;
      }
    }
  }
}