.modal{
    width: 500px;
    padding: 20px;
    text-align: center;
    background: white;
    border: 1px solid black;
    border-radius: 10px;
    font-size: 14px;

    // .title{
    //   display: flex;
    //   align-items: center;
    //   margin-left: 31%;

    //   img{
    //     padding-left: 2%;
    //     padding-bottom: 2%;
    //   }
    // }

    h1{
      font-family: 'Playfair Display', serif;
    }

    p{
        color:black;
        font-size: 18px;
    }

    .confirmButton{
      padding: 10px;
      border: 1px solid black;
      border-radius: 50px;
      margin: 30px auto 0 auto !important;
      width: 150px;
      font-weight:bolder;
      cursor: pointer;
      font-family: 'Playfair Display', serif;
    }
}

.overlay-custom-class-name
{
  background-color: rgba($color: #000000, $alpha: 0.5);
}

@media (max-width: 765px) {
  .modal{
    width: 300px;
    padding: 20px;
    text-align: center;
    background: white;
    border: 1px solid black;
    border-radius: 10px;
    font-size: 13px;

    h1{
      margin-top: 2px;
      font-family: 'Playfair Display', serif;
    }

    p{
        color:black;
        font-size: 13px;
    }

    .confirmButton{
      border: 1px solid black;
      border-radius: 50px;
      margin: 30px auto 0 auto !important;
      width: 150px;
      cursor: pointer;
      font-family: 'Playfair Display', serif;
    }
}
}