.mint {
  position: relative;
  min-height: calc(100vh - 150px);
  padding: 100px 0 10px 0;
  align-content: center;
  text-align: left;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  .numberImage {
    width: 150px;
    color: black;
    padding: 0 0 0 0;
    img {
      width: 100%;
    }
    position: absolute;
    top: 150px;
  }

  .contentWrapper {
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    .bannerWrapper {
      margin-bottom: 80px;
      .slogan {
        width: 100%;
        h1 {
          font-weight: 700;
          font-family: 'Playfair Display', serif;
          font-size: 45px;
        }
        h2 {
          font-weight: 600;
          font-family: 'Playfair Display', serif;
          font-size: 30px;
          margin-bottom: 25px;
        }
        p {
          font-size: 18px;
          line-height: 25px;
          font-weight: 400;
        }
      }
      .exampleImages {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        cursor: grab;
        img {
          max-width: 385px;
          max-height: 385px;
          width: 100%;
        }
      }
    }

    .mintBy{
      text-align: center;
      font-family: 'Playfair Display', serif;
      font-size: 20px;
      margin: auto;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .switchButton {
      background-color: white;
      border-radius: 60px;
      border: 1px black solid;
      width: 150px;
      height: 42px;
      vertical-align: middle;
      line-height: 42px;
      margin: auto;
      color: white;
      display: flex;
      font-family: 'Playfair Display', serif;
      font-weight: 600;
      margin-bottom: 20px;
      .stakeTab {
        width: 50%;
        background-color: #566F67 ;
        font-weight: 500;
        color: white;
        border-radius: 60px 0 0 60px;
        cursor: pointer;
        text-align: center;
      }
      .stakeTabUnstake {
        width: 50%;
        background-color: white;
        font-weight: 500;
        color: black;
        border-radius: 60px 0 0 60px;
        cursor: pointer;
        text-align: center;
      }
      .UnstakeTab {
        width: 50%;
        background-color: white;
        color: black;
        font-weight: 500;
        border-radius: 0 60px 60px 0;
        cursor: pointer;
        text-align: center;
      }
      .UnstakeTabUnstake {
        width: 50%;
        background-color: #566F67 ;
        color: white;
        font-weight: 500;
        border-radius: 0 60px 60px 0;
        cursor: pointer;
        text-align: center;
      }
    }

    .mintBy{
      font-size: 15px;
    }

    .remain,
    .price,
    .mintButton {
      display: block;
      margin-bottom: 50px;
      .name {
        color: black;
        font-family: 'Playfair Display', serif;
        font-size: 20px;
        letter-spacing: 0;
        font-weight: 600;
        margin-bottom: 20px;
      }
      .value {
        h1 {
          line-height: 50px;
          font-size: 50px;
          color: black;
          letter-spacing: 0;
          font-weight: 600;
          span {
            font-size: 16px;
          }
        }
      }

      .totalPrice{
        align-items: center;
        .cost{
          display: flex;
          align-items: center;
          justify-content: center;
          h2{
            font-weight: 600;
            text-align: center;
            span{
              font-size: 14px;
            }
          }
          img{
            width: 15px;
            height: 15px;
            margin-left: 5px;
            margin-top: 8px;
            cursor: pointer;
          }
        }

        h1{
          text-align: center;
          font-size: 14px;
          font-family: 'Playfair Display', serif;
        }

        p{
          text-align: center;
          font-size: 14px;
        }
      }
    }

    .inputWrap{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      img{
        width: 40px;
        cursor: pointer;
        user-select: none;
      }
      .inputAmount{
        margin: 0 40px;
        font-size: 30px;
        font-weight: 600;
        user-select: none;
      }
    }

    .mintButton {
      .name{
        text-align: center;
      }
      .button {
        margin: 20px auto 0 auto !important;
        width: 200px;
      }
    }

    .balance{
      text-align: center;
      margin-top: 10px;
      font-size: 15px;
      font-weight: 600;
    }

    .ownedNFTs{
      display: flex;
      justify-content: left;
      align-items: flex-start;
      gap: 20px;
      color: black;
    }

    .subtitle {
      margin-bottom: 20px;
      font-size: 21px;
      color: black;
      letter-spacing: 0;
      line-height: 34px;
      font-weight: 600;
    }
    .faq {
      margin-bottom: 100px;

      .question {
        font-size: 16px;
        color: black;
        letter-spacing: 0;
        font-weight: 300;
      }
    }
  }

  @media (max-width: 765px) {
    padding: 50px 0 0 0;
    .numberImage {
      width: 40%;
      height: auto;
      padding: 0 0 0 30px;
      top: 60px;
      img {
        width: 100%;
      }
    }

    .contentWrapper {
      padding: 50px 25px 0 25px;
      .bannerWrapper {
        margin-bottom: 60px;
        .slogan {
          h1 {
            font-size: 40px;
          }
          h2 {
            font-size: 25px;
            margin-bottom: 15px;
          }
          p {
            font-size: 16px;
            line-height: 23px;
          }
        }

        .exampleImages {
          margin-top: 20px;
          display: flex;
          img {
            width: auto;
            height: 250px;
          }
        }
      }

      .remain,
      .price,
      .mintButton {

      .totalPrice{
        margin-bottom: 30px;

        h1{
          text-align: center;
          font-size: 16px;
          margin-bottom: 10px;
        }

        h2 {
          font-size: 25px;
        }

        p{
          font-size: 16px;
        }
      }

        margin-bottom: 20px;
        .name {
          font-size: 15px;
        }
        .value {
          h1 {
            line-height: 42px;
            font-size: 42px;
            color: black;
            letter-spacing: 0;
            font-weight: 600;
            span {
              font-size: 16px;
            }
          }
        }
      }

      .inputWrap{
        margin-top: 20px;
        margin-bottom: 10px;

        .inputAmount{
          margin: 0 50px;
          font-size: 25px;
        }
      }

      .mintButton {
        margin-bottom: 20px;
        text-align: center;
        .button {
          width: 50%;
          margin-top: 0 !important;
        }
      }

      .subtitle {
        font-size: 16px;
        margin-bottom: 10px;
        line-height: initial;
      }
      .faq {
        margin-bottom: 30px;
        .question {
          font-size: 14px;
          color: black;
          letter-spacing: 0;
          font-weight: 300;
          margin-bottom: -20px;
        }
      }
    }
  }
}
