  .fullImg{
    max-width: 900px;
  }

.confirm-alert-overlay{
  background-color: rgba($color: #000000, $alpha: 0.8);
}

.wsaCard {
  .nftItem{
    position: relative;
    border: 1px solid lightgrey;
    width: 250px;
    img{
      width: 100%;
      cursor: pointer;
    }
    .bio{
      border-top: 1px solid lightgrey;
      padding: 10px 10px;
      background: white;
      .idAndTheme, .nameAndDate{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        font-size: 16px;
        img{
          border: 1px solid lightgrey;
          padding: 3px;
          border-radius: 50%;
          width: 20px;
          margin-right: 5px;
        }
      }

      .idAndTheme{
        padding-left: 5px;
      }

      .nameAndDate{
        margin-top: 5px;

        div{
          display: flex;
        align-items: center;
        }
      }
    }
    .placeHolder{
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 30%;
    }
  }
}

@media (max-width: 765px) {
  .fullImg{
    max-width: 500px;
  }

  .wsaCard {

    .nftItem{
      width: 100%;
      img{
        width: 100%;
      }

      .bio{

      .idAndTheme, .nameAndDate{
        font-weight: 700;
        font-size: 14px;
      }
    }

    }
  }
}
