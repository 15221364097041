.Countdown {
  margin: 0 auto;
}

.Countdown-col {
  display: inline-block;
}
.Countdown-col-element {
  margin: 0 0;
  display: flex;
  flex-direction: column;
}

.Countdown-col-element strong {
  text-align: center;
  font-weight: 600;
  color: black;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.Countdown-col-element .countdownUnit {
  color: white;
  text-align: center;
}
