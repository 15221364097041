.home {
  position: relative;
  min-height: 100vh;
  padding: 100px 30px 10px 30px;
  align-content: center;
  text-align: left;
  //display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  scroll-behavior: smooth;
  .banner {
    padding: 0 10px 50px 0;
    width: 100%;
    text-align: left;
    h1 {
      font-size: 40px;
      margin-bottom: 10px;
      font-family: 'Playfair Display', serif;
      font-weight: 900;
      span {
        margin-right: 10px;
      }
    }
    h2 {
      font-size: 35px;
      font-weight: 800;
      font-family: 'Playfair Display', serif;
    }
    h3 {
      font-size: 20px;
      margin-bottom: 20px;
      font-weight: 600;
      font-family: 'Playfair Display', serif;
    }
    p {
      font-size: 16px;
    }

    .addressRow{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 10px 0 20px 0;
      .addressWrap{
        display: block;
        align-items: center;
        width: 675px;

        justify-content: space-between;
        font-size: 14px;
        font-weight: 500;
        gap:10px;
        color: black;
        .key{
          flex-grow: 2;
        }
        .address{
          display: flex;
          // margin-left: 10px;
          // text-overflow: ellipsis;
          white-space: nowrap;
          width: 50vw;
          max-width: 600px;
          p{
            text-overflow: ellipsis;
            overflow-x: hidden;
            color: black;
            width: 75%;
          }
        }
      }

      .copy{
        margin-left: 40px;
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }

    .rightWrapper {
    }

    .socialMediaWrap {
      margin: 30px 0 0 5px;
      a {
        margin-right: 15px;
        img {
          width: 35px;
          height: 35px;
        }
      }
    }
  }

  .openSeaWrapper{
    position: relative;
    display: flex;
    padding: 0 20px;
    align-items: center;
    justify-content: space-between;
    height: 150px;

    border-top: solid black 1px;
    border-bottom: solid black 1px;

    padding: 0 80px;

    // background-color: black;
    // border: solid black 1px;
    // border-radius: 10px;
    margin: 20px 0;

    h1{
      font-family: PlayfairDisplayRoman-SemiBold;
      font-weight: 600;
      font-size: 24px;
      // color: white;
    }

    p{
      font-size: 18px;
      font-family: 'Open Sans', sans-serif;
      font-weight: 500;
      // color: white;
    }

    .openSeaButtonWrapper{
      border: solid black 1px;
      border-radius: 60px;
      width: 200px;
      &:hover{
        background-color: black;
      }
      p{
        padding: 10px;
        text-align: center;
        color: black;
        font-size: 16px;
        &:hover{
          color: white;
        }
      }
    }
  }

    // .openSeaButtonWrapper{
    //   display: flex;
    //   align-items: center;
    //   img {
    //     margin-right: 10px;
    //   }
    //   p{
    //     width: 80px;
    //     font-size: 14px;
    //     font-family: 'Playfair Display', serif;
    //     font-weight: 600;
    //   }

    //   .arrowImg{
    //     margin-left: 15px;
    //     margin-right: 0;
    //     width: 30px;
    //     height: 10px;
    //     margin-top: -2px;
    //   }
    // }

  .divider {
    margin: 30px 0;
    //background-image: linear-gradient(to right, #ff4e50, #facc26);
  }

  .subtitle {
    font-weight: bolder;
    font-size: 35px;
    font-family: 'Playfair Display', serif;
  }

  .itemEntre{
    margin-bottom: 100px;
  }

  .rightButton
  {
    margin-left: auto;
  }

  .introductionWrapper{
    margin-top: 0;
    position: relative;
    padding: 0;
    .subtitleWrapper{
      margin-bottom: 30px;
      position: relative;

      .numberImage{
        width: 130px;
        margin-left: 30px;
      }

      .leftTitle{
        position: absolute;
        bottom: 15px;
        left: 100px;
        max-width: 500px;
        width: 80%;
      }
      .rightImage{
        margin-left: auto !important;
        display: block;
        //float: right;
      }

      .frameNumber
      {
        width: 170px;
        margin-left: 30px;
      }

      .horizontalImage{
        width: 200px;
      }

      .rightTitle{
        position: absolute;
        bottom: 20px;
        right: 100px;
        padding-left: 20%;
        max-width: 500px;
        width: 80%;
      }

      .frameTitle{
        padding-left: 180px;
        width: 60vw;
        max-width: 450px;
      }


      .aestheticTitle{
        position: absolute;
        top: 50%;
        left: 100px;
        transform: translateY(-50%);
      }

      .algorithmTitle{
        left: initial;
        right: 100px;
      }

      .tokenTitle{
        left: initial;
        right: 100px;
        padding-left: 20%;
      }
    }

    img{
      width: 90%;
    }

    p{
      font-size: 16px;
      align-content: center;
      text-align: justify;
    }

    .frameBody
    {
      margin-bottom: 40px;
    }

    .illustrationImg{
      // border: 2px solid black;
      width: 100%;
    }

    .leftP{
      text-align: left;
      max-width: 500px;
      width: 40vw;
    }

    .rightP{
      text-align: right;
      margin-left: auto;
      max-width: 450px;
      width: 40vw;
    }

    .buttons{
      margin-top: 50px;
      display: flex;
      align-items: center;
      width: 100%;
      .arrowButtonWrap{
        margin-left: auto;
        display: flex;
        align-items: center;
        .rightArrow{
          margin-left: 15px;
          width: 30px;
          height: 10px;
          margin-top: -2px;
        }
      }

    }
  }

  @media (max-width: 765px) {
    padding: 80px 15px 10px 15px;

    .banner {
      text-align: center;
      padding: 0 10px 0 10px;
      h1 {
        font-size: 40px;
        margin-bottom: 0;
        text-align: left;
        width: 300px
      }
      h2 {
        font-size: 30px;
        text-align: left;
      }
      h3 {
        text-align: left;
      }

      p {
        font-size: 14px;
        text-align: left;
        opacity: 0.7;
      }
      .rightWrapper {
        padding-top: 0;
      }

      .button {
        margin: 20px auto 20px 0;
      }

      .addressRow{
        margin: 10px 0;
        .addressWrap{
          text-align: start;
          display: flex;
          justify-content: flex-start;
          .key{
            width: 150px;
            flex-grow: unset;
          }
          .address{
            width: auto;
            p{
              width: 100%;
            }
          }
        }

        .copy{
          margin-left: 50px;
        }
      }

      .socialMediaWrap {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        a {
          margin: 0 5px;
          img {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
    .openSeaWrapper{
      display: block;
      align-items: center;
      height: auto;
      padding: 20px 10px;
      h1{
        text-align:left;
        margin-bottom: 15px;
      }
  
      p{
        width: 250px;
        text-align: left;
        font-size: 16px;
        margin-bottom: 20px;
        br{
          display: none;
        }
      }
  
      .openSeaButtonWrapper{

        margin-bottom: 5px;
        padding: 10px 0;
        width: 250px;
        p{
          font-family: 'Playfair Display', serif;
          font-weight: 600;
          margin-bottom: 0;
          font-size: 15px;
          padding: 0;
        }
      }
    }

    // .openSeaWrapper{
    //   position: relative;
    //   display: block;
    //   padding: 0 20px;
      
    //   h1{
    //     font-family: PlayfairDisplayRoman-SemiBold;
    //     font-weight: 600;
    //     font-size: 25px;
    //     width: 150px;
    //     margin-bottom: 10px;
    //   }
  
    //   p{
    //     font-size: 14px;
    //     font-family: 'Open Sans', sans-serif;
    //     font-weight: 300;
    //     width: 100%; 
    //     margin-bottom: 20px;
    //   }

    //   br{
    //     display: none;
    //   }
  
    //   .openSeaButton{
    //     width: 210px;
    //     display: flex;
    //     text-align: center;
    //     border-radius: 50px;
    //     text-transform: capitalize;
    //     font-weight: 600;
    //     font-size: 18px;
    //     padding: 5px 20px;
    //     align-items: center;
    //     justify-content: space-between;
    //     background-color: transparent;
    //     font-family: 'Playfair Display', serif;
    //     border: 1px solid black;
    //     color: black;
    //     img{
    //       width: 25px;
    //       height: 25px;
    //     }
    //     p{
    //       width: 140px;
    //       font-size: 12px;
    //       font-family: 'Playfair Display', serif;
    //       font-weight: 600;
    //       margin-bottom: 0;
    //     }
    //   }
    // }

    .divider {
      margin: 20px 0;
      background-image: none !important;
    }

    .subtitle {
      font-size: 25px;
    }

    .itemEntre{
      margin-bottom: 60px;
    }

    .rightButton
    {
      margin-bottom: 10px;
    }

    .introductionWrapper{
      margin-top:20px;
      padding: 0 10px;

      .frameWrapper{
        // margin-top: -60px;
        margin-bottom: 30px;
      }

      .subtitleWrapper{

        .numberImage{
          width: 80px;
          margin-left: 0;
        }
        .leftTitle{
          left: 40px;
          bottom: 10px;
          width: 200px;
        }

        .frameNumber
        {
          width: 120px;
          margin-left: 30px;
        }

        .horizontalImage{
          width: 120px;
        }

        .rightTitle{
          right: 60px
        }

        .frameTitle
        {
          padding-left: 90px;
          width: 85%;
          top: 5px;
        }

        .aestheticTitle{
          left: 50px;
        }

        .algorithmTitle{
          left: initial;
          right: 90px;
        }
        .tokenTitle{
          left: initial;
          right: 20px;
          width: 300px;
          padding-left: 28%;
        }
      }

      .illustrationImg{
        margin-top: 15px;
        margin-left: auto;
        width: 100%;
      }

      .leftP{
        font-size: 14px;
        opacity: 0.7;
        text-align: left;
        width: 100%;
      }

      .rightP{
        font-size: 14px;
        opacity: 0.7;
        text-align: right;
        margin-left: 0;
        width: 100%;
      }

      .frameBody
      {
        margin-bottom: 20px;
      }

      .buttons{
        margin: 20px 0;
        display: flex;
        justify-content: space-between;
        .arrowButtonWrap{
          margin-left: 0;
          display: flex;
          align-items: center;
          .rightArrow{
            margin-left: 15px;
            width: 30px;
            height: 10px;
            margin-top: -2px;
          }
        }
      }
    }
  }
}