@import '~antd/dist/antd.css';
.App {
  text-align: center;
  background-color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  color: black;
  a,
  span,
  p,
  h5,
  h1,
  h2,
  h3,
  h4,
  h5{
    color: black;
    margin-bottom: 0;
  }
  .themeRed {
    color: black;
  }

  .themeYellow {
    color: black;
  }
  .mainContainer {
    width: 1100px;
    max-width: 100%;
    margin: 0 auto;
  }

  .button {
    cursor: pointer;
    user-select: none;
    &:hover {
      opacity: 0.8;
    }
  }

  .gradiantButton {
    width: 150px;
    text-align: center;
    border-radius: 50px;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 18px;
    padding: 10px 20px;
    background-color: transparent;
    font-family: 'Playfair Display', serif;
    border: 1px solid black;
    color: black;
  }

  .nonGradiantButton {
    font-family: 'Playfair Display', serif;
    width: 150px;
    text-align: center;
    border-radius: 50px;
    border: #fc9836 solid 1px;
    font-weight: 600;
    font-size: 18px;
    padding: 10px 20px;
  }

  .underLinedButton{
    font-family: 'Playfair Display', serif;
    text-align: left;
    font-weight: 600;
    font-size: 18px;
    border-bottom: 1px solid transparent;
    padding-bottom: 5px;
    transition: all 0.1s ease;
    width: 100%;
    color: black;
    &:hover{
      border-bottom: 1px solid black;
    }
  }

  .divider {
    margin: 30px 0;
    height: 1px;
    background-color: black;
    //background-image: linear-gradient(to right, #ff4e50, #facc26);
  }

  .buttonDisabled{
    background: none;
    color: grey;
    border-color: grey;
    //background-color: grey !important;
    cursor: not-allowed;
    &:hover{
      opacity: 1 !important;
    }
  }

  .normalFont{
     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  }

  @media(max-width: 765px){
    .gradiantButton, .nonGradiantButton {
      background: white;
      width: 120px;
      font-size: 15px;
      padding: 10px 0;
    }

    .divider {
      margin: 30px 20px;
      height: 1px;
      background-color: black;
      //background-image: linear-gradient(to right, #ff4e50, #facc26);
    }
  }
}
