.token{
  padding: 150px 0 10px 0;
  text-align: left;
  position: relative;
  img{
    width: 80%;
  }

  h2{
    font-family: 'Playfair Display', serif;
    margin-bottom: 20px !important;
    font-size: 22px;
    font-weight: bold;
  }

  p,.normalText{
    margin: 20px 0;
    opacity: 0.6;
  }

  .ruleWrapper{
    margin: 50px 0;
  }

  .token{
    position: absolute;
    bottom: 40px;
    right: 0;
    width: 80px;
  }

  .rewardWrap{
    width: calc(100% - 200px);
    p{
      text-align: left;
      margin: 0;
    }

    .normalText{
      text-align: left;
      margin: 0;
      display: flex;
      -ms-overflow-style: none; /* IE and Edge */
      overflow-x: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      .MathJax{
        margin: 0 5px !important;
      }
    }

    .inlineMathjax{
      display: block;
      div{
        display: inline-block;
      }
    }
  }

  @media(max-width: 765px){
    padding: 100px 20px;

    p{
      text-align: left;
      line-height: 20px;
      margin: 10px 0;
    }

    img{
      margin-top: 30px;
    }

    .rewardWrap{
      width: calc(100% - 50px);
    }
  }
}