.roadmap{
  position: relative;

    .outerWrap{
      margin-top: -80px;
      padding-left: 10%;
      .init{
        position: relative;
        left: 208px;
        display: inline-block;
        transform: translateX(-50%);
        font-size: 30px;
        font-weight: 600;
        font-family: 'Playfair Display', serif;
      }
      .contentWrap{
        width: 100%;
        height: 20px;
        position: relative;


        .textWrap{
          height: 16px;
          position: relative;
          .left, .dotWrapper, .right{
            height: 16px;
            font-size: 16px;
            color: black;
            display: inline-block;
            vertical-align: middle;
            line-height: 16px;
            position: relative;
          }
          .left{
            width: 200px;
            text-align: right;
            padding-right: 20px;
            font-size: 16px;
            span{
              display: block;
              margin-bottom: 10px;
              font-size: 18px;
              font-weight: 600;
            }
          }
          .dotWrapper{
            width: 16px;
            height: 16px;
            background-color: black;
            border-radius: 50%;
          }
          .right{
            width: calc(100% - 216px);
            padding-left: 20px;
            position: relative;
            div{
              position: absolute;
              top:50%;
              transform: translateY(-50%);
              span{
                font-size: 18px;
                font-weight: 600;
                margin-right: 10px;
              }
            }
          }

          .doubleLineWrap{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            div{
              position: relative;
              top: initial;
              transform:translateY(0);
              p{
                margin: 5px 0;
              }
            }
          }
        }

      }

      .verticalGreenLineWrap{
        height: 80px;
        width: 1px;
        margin-bottom: -5px;
        background: black;
        margin-left: 208px;
      }
    }

    @media(max-width: 765px){

      .subtitleWrapper{
        .rightTitle{
          top: 50%;
          left: 50%;
          right: initial;
          transform: translate(-50%,-50%);
        }
      }
      .outerWrap{
        margin-top: 0;
        padding-left: 0;
        .init{
          left: 50%;
          font-size: 25px;
        }
        .contentWrap{
          width: 100%;
          height: 20px;
          position: relative;

          .textWrap{
            height: 16px;
            position: relative;
            .left, .dotWrapper, .right{
              height: 14px;
              font-size: 14px;
              display: inline-block;
              vertical-align: middle;
              line-height: 14px;
              position: relative;
            }
            .left{
              width: calc(50% - 7px);
              text-align: right;
              padding-right: 10px;
              font-size: 14px;
              line-height: 16px;
              span{
                display: block;
                margin-bottom: 10px;
                font-size: 18px;
                font-weight: 600;
              }
            }
            .dotWrapper{
              width: 14px;
              height: 14px;
              border-radius: 50%;
            }
            .right{
              width: calc(50% - 7px);
              padding-left: 10px;
              position: relative;
              div{
                position: absolute;
                top:50%;
                transform: translateY(-50%);
                line-height: 16px;
                span{
                  font-size: 16px;
                  font-weight: 600;
                  margin-right: 0;
                  margin-bottom: 10px;
                  display: block;
                }
              }
            }

            .doubleLineWrap{
              display: block;
              justify-content: flex-start;
              align-items: center;
              div{
                position: relative;
                top: initial;
                transform:translateY(0);
                p{
                  margin: 0;
                  text-align: left;
                }
              }
            }
          }

        }

        .verticalGreenLineWrap{
          height: 80px;
          width: 1px;
          margin-bottom: -5px;
          margin-left: calc(50%);;
        }
      }
    }

  }