.footer {
  padding-bottom: 20px;

  .socialMediaWrap {
    margin: 10px 0 50px 0;
    a {
      margin: 0 15px;
      img {
        width: 25px;
      }
    }
  }
}
