.wallet{
  padding: 150px 0 10px 0;
  text-align: left;
  position: relative;

  .walletImg{
    position: absolute;
    width: 150px;
    right: 0;
    top: 100px;
  }
  .subtitle{
    font-size: 30px;
    font-family: 'Playfair Display', serif;
    font-weight: 800;
    margin-bottom: 30px;
  }

  .wsaWrapper, .wscWrapper {
    position: relative;
    padding: 0 80px;
    margin-bottom: 60px;
  }
  .wscWrapper{
    .claimRow{
      display: flex;
      align-items: center;
    }
    .balanceRow{
      display: flex;
      align-items: center;
      width: 500px;
      justify-content: flex-start;
      font-size: 18px;
      font-weight: 500;
      margin: 10px 0;
      .key{
        width: 210px;
      }
      .value{
        span{
          font-weight: 600;
          margin-right: 10px;
        }
      }
    }

    .addressRow{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 10px 0 20px 0;
      .addressWrap{
        display: flex;
        align-items: center;
        width: 675px;
        max-width: calc(100% - 50px);

        justify-content: space-between;
        font-size: 18px;
        font-weight: 500;
        gap:10px;
        .key{
          flex-grow: 2;
          min-width: 200px;
        }
        .address{
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow-x: hidden;
          max-width: calc(100% - 150px);
        }
      }

      .copy{
        margin-left: 50px;
        width: 30px;
        cursor: pointer;
      }
    }
    .button{
      margin-left: 50px;
    }
  }

  .ownedNFTs{
    display: flex;
    gap: 16px;
    justify-content: flex-start;
    flex-wrap: wrap;
    .wsaCard{
      width: calc(25% - 32px) !important;
      .nftItem{
        width: 100% !important;
      }
    }
  }

  @media(max-width: 765px){
    padding: 100px 0 10px 0;
    .walletImg{
      width: 100px;
      top: initial;
      bottom: 100px;
    }
    .subtitle{
      font-size: 25px;
      margin-bottom: 20px;
    }

    .wsaWrapper, .wscWrapper {
      position: relative;
      padding: 0 20px;
      margin-bottom: 40px;
    }
    .wscWrapper{

      .balanceRow{
        display: flex;
        flex-direction: column;
        text-align: left;
        align-items: flex-start;

        font-size: 15px;
        .value{
          span{
            font-size: 30px;
          }
        }
      }
      .addressRow{
        .addressWrap{
          max-width: calc(100% - 30px);
          font-size: 15px;
          .address{
            max-width: calc(100% - 120px);
          }
        }

        .copy{
          width: 20px;
          margin-left: 0;
        }
      }
      .button{
        margin-left: 0;
        width: 200px;
      }
    }

    .ownedNFTs{
      position: relative;
      justify-content: space-between;
      gap: 8px;
      .wsaCard{
        width: calc(50% - 4px) !important;
      }
    }
  }
}